import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'

const CateringPage = () => {
  // Pull content from DatoCMS via GraphQL
  const cateringQuery = useStaticQuery(graphql`
  query cateringQuery {
    allDatoCmsCateringMenu {
      nodes {
        cateredBoxedLunchesMenu {
          url
        }
        fullCateringMenu {
          url
        }
      }
    }
  }`);

  // Our DatoCMS data structure has everything several layers deep, so move it all into convenient constants
  const data = cateringQuery.allDatoCmsCateringMenu.nodes[0];
  const pdfBoxedLunches = data.cateredBoxedLunchesMenu.url;
  const pdfFullCatering = data.fullCateringMenu.url;

  // We're finally ready to actually render our components, using our CMS-provided data!
  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>Catering</h2>
            <p style={{ textTransform: 'none' }}>Contact <a href="mailto:catering@fssoklahoma.com">catering@fssoklahoma.com</a> for a quote.</p>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner menu">
            <h2 className="major">Boxed Lunches</h2>
            <p className="date"></p>
            <section>
              <ul className="actions">
                <li><a href={pdfBoxedLunches} className="button primary" target="_blank" rel="noreferrer">Download Catered Boxed Lunches Menu</a></li>
              </ul>
            </section>
          </div>
        </div>
        <div className="wrapper alt style3">
          <div className="inner menu">
            <h2 className="major">Full Catering</h2>
            <p className="date"></p>
            <section>
              <ul className="actions">
                <li><a href={pdfFullCatering} className="button primary" target="_blank" rel="noreferrer">Download Full Catering Menu</a></li>
              </ul>
            </section>
          </div>
        </div>
      </section>
    </Layout>);
}

export default CateringPage;
